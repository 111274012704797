import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Light from "../icons/half-2-light.svg";

const ThankyouWebinar = ({ location }) => {
  console.log("location: ", location.state);

  return (
    <Layout>
      <Seo title={`Intranet 80.20: richiesta webinar`} description={``} robots={`noindex`} />

      <div className="hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <div className="pb-3 text-center">
                <h1 className="hero__title">Richiesta inviata con successo.</h1>
                <p>Controlla la tua casella email e troverai il link del video!</p>
                <Link to="/webinar/" title="Scopri altri webinar" className="btn-link">
                  <span>Guarda altri webinar</span>
                </Link>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <Half8Solid className="ico-svg ico-half-8-solid position-static" />
                  <Half2Light className="ico-svg ico-half-2-light position-static d-block ms-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ThankyouWebinar;
